import React, { FC, useCallback, useMemo, useState } from 'react'
import { ThemeContext } from './ThemeContext'
import { Muted, Primary, Secondary } from '../theme'

const ThemeProvider: FC = ({ children }) => {
	const [isLightTheme, setIsLightTheme] = useState<boolean>(true)

	const toggleTheme = useCallback(() => setIsLightTheme(prevState => !prevState), [])

	const useColorModeValue = useCallback((light: string, dark: string) => isLightTheme ? light : dark, [isLightTheme])

	const theme: 'light' | 'dark' = useMemo(() => isLightTheme ? 'light' : 'dark', [isLightTheme])

	const themeValues = {
		Primary: {
			Base: useColorModeValue(Primary.Light.Base, Primary.Dark.Base),
			Hover: useColorModeValue(Primary.Light.Hover, Primary.Dark.Hover),
			Pressed: useColorModeValue(Primary.Light.Pressed, Primary.Dark.Pressed),
			Border: useColorModeValue(Primary.Light.Border, Primary.Dark.Border),
			Background: useColorModeValue(Primary.Light.Background, Primary.Dark.Background)
		},
		Secondary: {
			Base: useColorModeValue(Secondary.Light.Base, Secondary.Dark.Base),
			Hover: useColorModeValue(Secondary.Light.Hover, Secondary.Dark.Hover),
			Pressed: useColorModeValue(Secondary.Light.Pressed, Secondary.Dark.Pressed),
			Border: useColorModeValue(Secondary.Light.Border, Secondary.Dark.Border),
			Background: useColorModeValue(Secondary.Light.Background, Secondary.Dark.Background)
		},
		Muted: {
			Content1: useColorModeValue(Muted.Light.Content1, Muted.Dark.Content1),
			Content2: useColorModeValue(Muted.Light.Content2, Muted.Dark.Content2),
			Content3: useColorModeValue(Muted.Light.Content3, Muted.Dark.Content3),
			Border: useColorModeValue(Muted.Light.Border, Muted.Dark.Border),
			BGSecondary: useColorModeValue(Muted.Light.BGSecondary, Muted.Dark.BGSecondary),
			BGPrimary: useColorModeValue(Muted.Light.BGPrimary, Muted.Dark.BGPrimary),
			SingleTone: useColorModeValue('#000', '#fff')
		}
	}

	const contextValue = useMemo(() => ({ theme, toggleTheme, useColorModeValue, themeValues }), [theme, toggleTheme, useColorModeValue, themeValues])

	return (
		<ThemeContext.Provider value={contextValue}>
			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeProvider

import { fetchFactory } from '../../../utils/fetchFactory'

export const getHouse = (houseId: string) => {
	return fetchFactory(`/api/admin/space/${houseId}`)
}

export const postVisit = (visit: Visit) => {
	return fetchFactory('/api/admin/visit', {
		method: 'POST',
		body: JSON.stringify(visit)
	})
}
import { createContext } from 'react'

type VisitContextProps = {
	visit?: Visit
	initVisit: (spaceId: string) => void
	deleteVisit: () => void
	checkVisit: () => void
	addIntervention: (intervention: InterventionData) => void
	modifyIntervention: (intervention: InterventionData, index: number) => void
	deleteIntervention: (index: number) => void
}

const context: VisitContextProps = {
	visit: undefined,
	initVisit: () => {
	},
	deleteVisit: () => {
	},
	checkVisit: () => {
	},
	addIntervention: () => {
	},
	modifyIntervention: () => {
	},
	deleteIntervention: () => {
	}
}

export const VisitContext = createContext(context)
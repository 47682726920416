import React from 'react'
import { Field } from 'react-final-form'
import { FormControl, MenuItem, Select, Stack } from '@mui/material'
import ErrorMessage from './ErrorMessage'
import Text from './Text'

type SelectInputProps = {
	name: string
	validate?: any
	label?: string
	selectOptions: SelectOption[]
	disabled?: boolean,
	onChange?: any
	defaultValue?: SelectOption
}

const SelectInput: React.FC<SelectInputProps> = (
	{
		name,
		validate,
		selectOptions,
		label,
		disabled,
		defaultValue
	}) => {

	return (
		<Field name={name} validate={validate} initialValue={defaultValue ? defaultValue.value : ""}>
			{({ input, meta }) => {
				if (selectOptions.length === 1) {
					input.onChange(selectOptions[0].value)
				} else if (defaultValue && !input.value) {
					input.onChange(defaultValue.value)
				}

				return (
					<Stack direction="column" spacing={1}>
						<Text variant="subtitle1">{label}</Text>
						<FormControl sx={{ height: '40px' }} size="small">
							<Select
								disabled={disabled}
								readOnly={selectOptions.length < 2}
								displayEmpty={!defaultValue}
								color="primary"
								sx={{ height: '40px', borderRadius: '8px' }}
								value={selectOptions.length === 1 ? selectOptions[0].value : input.value}
								onChange={input.onChange}
							>
								<MenuItem disabled value="">
									<Text variant="subtitle1">-</Text>
								</MenuItem>
								{selectOptions.map(option => (
									<MenuItem key={option.value} value={option.value}>
										<Text variant="subtitle1">{option.label}</Text>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<ErrorMessage disable={!(meta.error && meta.touched)} error={meta.error} />
					</Stack>
				)
			}}
		</Field>
	)
}

export default SelectInput
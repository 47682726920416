import {
	logOut as logoutAction,
	refreshToken as refreshTokenAction
} from '../modules/login/services/loginService/loginActions'
import {
	refresh as refreshApi,
	storeLocalUser as storeLocalUserApi
} from '../modules/login/services/loginService/loginApi'
import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router'
import HomePage from '../modules/home/pages/HomePage'
import {getUserByEmail} from '../modules/login/services/userApi'
import {UserContext} from '../modules/login/services/context/UserContext'
import HouseFoundPage from '../modules/scan/pages/HouseFoundPage'
import AddingHousePage from '../modules/addingSpace/pages/AddingHousePage'
import {BrowserRouter} from 'react-router-dom'
import {getEquipmentInfo, getSpaceInfo} from '../modules/addingSpace/services/addingSpaceApi'
import {CodeContext} from '../modules/scan/services/context/CodeContext'
import {APP_ROUTES} from './routesConstants'
import VisitPage from '../modules/visit/pages/VisitPage'
import ImportPage from '../modules/import/pages/ImportPage'
import EquipmentPage from '../modules/intervention/pages/EquipmentPage'
import AddingEquipmentPage from '../modules/addingSpace/pages/AddingEquipmentPage'
import InterventionPage from '../modules/intervention/pages/InterventionPage'
import VisitEndPage from '../modules/visit/pages/VisitEndPage'
import VisitConfirmationPage from '../modules/visit/pages/VisitConfirmationPage'
import ClientEntityFoundPage from '../modules/scan/pages/ClientEntityFoundPage'
import MentionLegal from '../modules/mentionLegal/page/MentionLegal'

/**
 * Interval de rafraîchissement du token, en secondes.
 */
const REFRESH_INTERVAL = 1700

interface IProps {
	location: {
		pathname: string
	},
	logout: () => void,
	refreshAction: (token: string) => void,
	user: {
		token: string,
		authenticated: boolean
		email: string
		firstname: string
		lastname: string
	}
}

const App = ({ location, user, logout, refreshAction }: IProps) => {
	const { setUser } = useContext(UserContext)
	const { setSpaceInfo, setEquipmentDataInfo } = useContext(CodeContext)

	const refresh = React.useCallback(() => {
		if (user.token) {
			const token = localStorage.getItem('token')
			refreshApi(token).then((json: any) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
	}, [user, refreshAction])

	React.useEffect(() => {
		refresh()
		const refreshInterval = setInterval(() => {
			refresh()
		}, REFRESH_INTERVAL * 1000)
		// Must user
		if (user.token) {
			const { email, firstname, lastname } = user

			// Call user API first to instantly display the home page
			getUserByEmail()
				.then((user) => setUser({ id: user.id, email, firstname, lastname, landlordId: user.landlordId, logoPath: user.logoPath, type: user.type }))

			Promise.all([getSpaceInfo(), getEquipmentInfo()])
				.then(([space, equipment]) => {
					setSpaceInfo(space)
					setEquipmentDataInfo(equipment)
				})
		}
		return () => {
			if (refreshInterval) {
				clearInterval(refreshInterval)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (user.authenticated) {
		if(user.email.includes("@ipsosenso.com")){
			return (
				<BrowserRouter>
					<Switch>
						<Route exact path="/" render={() => <Redirect to={APP_ROUTES.HOME}/>} />
						<Route exact path={APP_ROUTES.HOME} component={HomePage} />
						<Route exact path={APP_ROUTES.MENTION_LEGAL} component={MentionLegal} />
						<Route exact path={APP_ROUTES.FOUND} component={HouseFoundPage} />
						<Route exact path={APP_ROUTES.ADD_HOUSE} component={AddingHousePage} />
						<Route exact path={APP_ROUTES.ADD_EQUIPMENT} component={AddingEquipmentPage} />
						<Route exact path={APP_ROUTES.VISIT} component={VisitPage} />
						<Route exact path={APP_ROUTES.EQUIPMENT} component={EquipmentPage} />
						<Route exact path={APP_ROUTES.IMPORT} component={ImportPage} />
						<Route exact path={APP_ROUTES.INTERVENTION} component={InterventionPage} />
						<Route exact path={APP_ROUTES.VISIT_END} component={VisitEndPage} />
						<Route exact path={APP_ROUTES.VISIT_CONFIRMATION} component={VisitConfirmationPage} />
						<Route exact path={APP_ROUTES.CLIENT_FOUND} component={ClientEntityFoundPage} />
						<Route path="*" component={HomePage} />
					</Switch>
				</BrowserRouter>
			)
		} else {
			return (
				<BrowserRouter>
					<Switch>
						<Route exact path="/" render={() => <Redirect to={APP_ROUTES.HOME}/>} />
						<Route exact path={APP_ROUTES.HOME} component={HomePage} />
						<Route exact path={APP_ROUTES.MENTION_LEGAL} component={MentionLegal} />
						<Route exact path={APP_ROUTES.FOUND} component={HouseFoundPage} />
						<Route exact path={APP_ROUTES.ADD_HOUSE} component={AddingHousePage} />
						<Route exact path={APP_ROUTES.ADD_EQUIPMENT} component={AddingEquipmentPage} />
						<Route exact path={APP_ROUTES.VISIT} component={VisitPage} />
						<Route exact path={APP_ROUTES.EQUIPMENT} component={EquipmentPage} />
						<Route exact path={APP_ROUTES.INTERVENTION} component={InterventionPage} />
						<Route exact path={APP_ROUTES.VISIT_END} component={VisitEndPage} />
						<Route exact path={APP_ROUTES.VISIT_CONFIRMATION} component={VisitConfirmationPage} />
						<Route exact path={APP_ROUTES.CLIENT_FOUND} component={ClientEntityFoundPage} />
						<Route path="*" component={HomePage} />
					</Switch>
				</BrowserRouter>
			)
		}
	}
	return (<Redirect to={{
		pathname: "/login",
		state: { nextPathname: location.pathname }
	}}/>)
}

const mapStateToProps = (state: any) => ({
	user: state.user
})

const actions = {
	refreshAction: refreshTokenAction,
	logout: logoutAction
}

export default connect(mapStateToProps, actions)(App)

import React, { useContext, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Form } from 'react-final-form'
import { Box, Stack } from '@mui/material'
import SelectInput from '../../../components/SelectInput'
import { InterventionFieldNames, InterventionTypes } from '../services/interventionConstants'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'
import { ThemeContext } from '../../../config/context/ThemeContext'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { useHistory } from 'react-router'
import { CodeContext } from '../../scan/services/context/CodeContext'
import { VisitContext } from '../../visit/services/VisitContext'
import { ToastContext } from '../../toast/ToastContext'
import { ToastType } from '../../toast/toastConstants'
import Popin from '../../../components/Popin'
import Text from '../../../components/Text'


const InterventionPage: React.FC = () => {
	const intl = useIntl()
	const location = useLocation()
	const history = useHistory()

	const { themeValues } = useContext(ThemeContext)
	const { equipmentInfo, houseInfo } = useContext(CodeContext)
	const { addIntervention, visit, modifyIntervention, deleteIntervention, initVisit } = useContext(VisitContext)
	const { addToast } = useContext(ToastContext)

	const isAdd = useMemo(() => (location.state as unknown as { isAdd: boolean }).isAdd, [location.state])
	const interventionIndex = useMemo(() => (location.state as unknown as { interventionIndex?: number })?.interventionIndex, [location.state])
	const isDeleting = useMemo(() => (location.state as unknown as { isDeleting?: boolean })?.isDeleting, [location.state])

	const [disableButton, setDisableButton] = useState<boolean>(true)
	const [values, setValues] = useState<Record<string, any>>()
	const [openPopin, setOpenPopin] = useState<boolean>(false)

	// i18n
	const addTitleMessage = { id: 'intervention.subtitle.add' }
	const modifyTitleMessage = { id: 'intervention.subtitle.modify' }
	const titleMessage = isAdd ? addTitleMessage : modifyTitleMessage
	const subtitleTypeMessage = { id: 'intervention.fields.type' }
	const subtitleCommentMessage = { id: 'intervention.fields.comment' }
	const deleteMessage = { id: 'intervention.buttons.delete' }
	const modifyMessage = { id: 'intervention.buttons.modify' }
	const validateMessage = { id: 'intervention.buttons.validate' }
	const returnToVisitMessage = { id: 'visit.buttons.return' }
	const returnToEquipementMessage = { id: 'intervention.buttons.returnToEquipment' }
	const mainButtonMessage = isAdd ? validateMessage : modifyMessage
	const secondaryButtonMessage = isAdd ? returnToEquipementMessage : returnToVisitMessage
	const addInterventionSuccessMessage = { id: 'intervention.success' }
	const confirmMessage = { id: 'global.buttons.confirm' }
	const popinTitleMessage = { id: 'intervention.popin.title' }
	const popinMessage = { id: 'intervention.popin.content' }

	// Colors
	const secondaryBorderColor = themeValues.Secondary.Border
	const secondaryBackgroundColor = themeValues.Secondary.Background
	const secondaryColor = themeValues.Secondary.Base
	const textColor = themeValues.Muted.Content2

	const interventionSelectionOptions = (Object.keys(InterventionTypes) as Array<keyof typeof InterventionTypes>).map(key => {
		const message = { id: `intervention.fields.selectLabel.${InterventionTypes[key]}` }
		return ({
			value: InterventionTypes[key],
			label: intl.formatMessage(message)
		})
	})

	const addInterventionSubmit = () => {
		if (equipmentInfo && values) {
			const newIntervention: InterventionData = {
				equipmentId: equipmentInfo.id,
				type: equipmentInfo.type,
				brand: equipmentInfo.brand,
				model: equipmentInfo.model,
				comment: values[InterventionFieldNames.COMMENT],
				interventionType: values[InterventionFieldNames.TYPE],
				date: new Date().toISOString(),
				isDeleting: isDeleting || false
			}
			if (!visit && houseInfo) {
				initVisit(houseInfo.id)
			}
			if(isDeleting) {
				newIntervention.interventionType=interventionSelectionOptions[2].value
			}
			addIntervention(newIntervention)
			addToast(ToastType.SUCCESS, intl.formatMessage(addInterventionSuccessMessage))
			history.push(APP_ROUTES.VISIT)
		}
	}

	const modifyInterventionSubmit = () => {
		if (interventionIndex !== undefined && values && visit) {
			const newIntervention: InterventionData = {
				...visit.interventions[interventionIndex],
				comment: values[InterventionFieldNames.COMMENT],
				interventionType: values[InterventionFieldNames.TYPE]
			}
			modifyIntervention(newIntervention, interventionIndex)
			addToast(ToastType.SUCCESS, intl.formatMessage(addInterventionSuccessMessage))
			history.push(APP_ROUTES.VISIT)
		}
	}
	const defaultOptions = () => {
		if (isDeleting) {
			return interventionSelectionOptions[2]
		} else if (visit && interventionIndex !== undefined) {
			const selectedInterventionType = visit.interventions[interventionIndex].interventionType
			return interventionSelectionOptions.find(option => option.value === selectedInterventionType)
		}
	}

	const defaultComment = () => {
		if (!isAdd && visit && interventionIndex !== undefined) {
			return visit.interventions[interventionIndex].comment
		}
	}

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => isAdd ? addInterventionSubmit() : modifyInterventionSubmit(),
				label: intl.formatMessage(mainButtonMessage),
				disabled: disableButton
			}}
			secondaryButtonProps={{
				onClick: () => isAdd ? history.push(APP_ROUTES.EQUIPMENT) : history.push(APP_ROUTES.VISIT),
				label: intl.formatMessage(secondaryButtonMessage)
			}}
		>
			<Card title={intl.formatMessage(titleMessage)}>
				<Form
					onSubmit={() => {
					}}
					render={({ handleSubmit, pristine, submitting, hasSubmitErrors, values }) => {
						if (submitting || hasSubmitErrors || !values[InterventionFieldNames.COMMENT]) {
							setDisableButton(true)
						} else {
							setDisableButton(false)
						}

						setValues(values)

						return (
							<form style={{ width: '100%' }} onSubmit={handleSubmit}>
								<Stack spacing={2} width="100%">
									<SelectInput
										name={InterventionFieldNames.TYPE}
										selectOptions={interventionSelectionOptions}
										label={intl.formatMessage(subtitleTypeMessage)}
										defaultValue={defaultOptions()}
										disabled={isDeleting}
									/>
									<TextInput
										name={InterventionFieldNames.COMMENT}
										label={intl.formatMessage(subtitleCommentMessage)}
										defaultValue={defaultComment()}
										multiline
										required
									/>
									{!isAdd && (
										<Button
											label={intl.formatMessage(deleteMessage)}
											variant="contained"
											color="secondary"
											sx={{
												borderColor: secondaryBorderColor,
												backgroundColor: secondaryBackgroundColor,
												color: secondaryColor
											}}
											onClick={() => setOpenPopin(true)}
										/>
									)}
								</Stack>
							</form>
						)
					}}
				/>
			</Card>
			<Popin modalOpen={openPopin} setModalOpen={setOpenPopin} label={intl.formatMessage(popinTitleMessage)}>
				<Stack spacing={2} width="100%">
					<Box>
						<Text sx={{ color: textColor }} variant="subtitle1">{intl.formatMessage(popinMessage)}</Text>
					</Box>
					<Button
						label={intl.formatMessage(confirmMessage)}
						variant="contained"
						color="primary"
						onClick={() => {
							if (interventionIndex !== undefined) {
								deleteIntervention(interventionIndex)
								history.push(APP_ROUTES.VISIT)
							}
						}}
					/>
				</Stack>
			</Popin>
		</PageContainer>
	)
}

export default InterventionPage

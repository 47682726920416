export enum HouseFieldNames {
	BUILDING_PROGRAM_NAME = 'buildingProgramName',
	BUILDING_NUMBER = 'buildingNumber',
	BUILDING_ENTRY = 'buildingEntry',
	SPACE_NUMBER = 'spaceNumber',
}

export enum EquipmentFieldNames {
	BRAND = 'brand',
	TYPE = 'type',
	MODEL = 'model',
	SERIAL_NUMBER = 'serialNumber',
	COMMISSIONING_DATE = 'commissioningDate',
}
import { fetchFactory } from '../../../utils/fetchFactory'

export const getSpaceInfo = () => fetchFactory('/api/admin/space/info')

export const getEquipmentInfo = () => fetchFactory('/api/admin/equipment/info')

export const postQrCode = (spaceId: string, qrCode: string) => fetchFactory('/api/admin/space/add', { method: 'POST', body: JSON.stringify({ spaceId, qrCode }) })

export const updateQrCodeSpace = (body: { qrCode: string, newQrCode: string }) => fetchFactory('/api/admin/space/update', { method: 'POST', body: JSON.stringify(body) })

export const postEquipment = (body: AddEquipmentBody) => fetchFactory('/api/admin/equipment/add',
	{
		method: 'POST',
		body: JSON.stringify(body)
	})

export const checkSpace = (spaceId: string) => fetchFactory(`/api/admin/space/check/${spaceId}`)

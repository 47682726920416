import * as api from './configurationApi'

export const GET_CONFIGURATION_REQUEST = 'GET_CONFIGURATION_REQUEST '
export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS'
export const GET_CONFIGURATION_ERROR = 'GET_CONFIGURATION_ERROR'

export const getConfiguration = () => dispatch => {
	dispatch({ type: GET_CONFIGURATION_REQUEST })
	return api.getConfiguration()
		.then(configuration => {
			dispatch({ type: GET_CONFIGURATION_SUCCESS, configuration })
		})
		.catch(e => dispatch({ type: GET_CONFIGURATION_ERROR, e }))
}

import React, { useContext, useMemo } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { Box, Stack } from '@mui/material'
import FileSearching from '../../../assets/images/file_searching.svg'
import HouseSearching from '../../../assets/images/house_searching.svg'
import Text from '../../../components/Text'
import CheckIcon from '@mui/icons-material/Check'
import InfoCell from '../../../components/layout/InfoCell'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { CodeContext } from '../services/context/CodeContext'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { VisitContext } from '../../visit/services/VisitContext'
import { EquipmentFieldNames } from '../../addingSpace/services/addingSpaceConstants'
import { convertDate } from '../../../utils/dateUtils'
import { ThemeContext } from '../../../config/context/ThemeContext'

const HouseFoundPage: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()
	const location = useLocation()
	const { houseInfoOptions, equipmentInfoOptions, houseInfo } = useContext(CodeContext)
	const { initVisit } = useContext(VisitContext)
	const { themeValues } = useContext(ThemeContext)

	const isLinked = useMemo(() => (location.state as unknown as { isLinked: boolean }).isLinked, [location.state])
	const isEquipment = useMemo(() => (location.state as unknown as { isEquipment: boolean }).isEquipment, [location.state])
	const data = useMemo(() => isEquipment ? equipmentInfoOptions || [] : houseInfoOptions || [], [isEquipment, equipmentInfoOptions, houseInfoOptions])

	//i18n
	const beginVisitMessage = { id: 'global.buttons.beginVisit' }
	const goBackMessage = { id: 'global.buttons.goBack' }
	const consultSheetMessage = { id: 'global.buttons.consultSheet' }
	const returnToVisitMessage = { id: 'global.buttons.returnToVisit' }
	const houseFoundMessage = { id: 'visit.found' }
	const linkedEquipmentsMessage = { id: 'visit.subtitle.linkedEquipment' }
	const houseLinkedMessage = { id: 'visit.linked' }
	const equipmentFoundMessage = { id: 'visit.equipmentFound' }
	const titleMesssage = isEquipment ? equipmentFoundMessage : isLinked ? houseLinkedMessage : houseFoundMessage
	const mainButtonMessage = isEquipment ? consultSheetMessage : beginVisitMessage
	const secondaryButtonMessage = isEquipment ? returnToVisitMessage : goBackMessage
	const typeMessage = { id: 'visit.equipmentInfo.type' }
	const brandMessage = { id: 'visit.equipmentInfo.brand' }
	const visitDateMessage = { id: 'visit.equipmentInfo.visitDate' }
	const latestWorksInterventionDateMessage = { id: 'visit.equipmentInfo.latestWorksInterventionDate' }
	const latestCurativeInterventionDateMessage = { id: 'visit.equipmentInfo.latestCurativeInterventionDate' }

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => {
					if (!isEquipment && houseInfo) {
						initVisit(houseInfo.id || '')
					}
					history.push(isEquipment ? APP_ROUTES.EQUIPMENT : APP_ROUTES.VISIT)
				},
				label: intl.formatMessage(mainButtonMessage)
			}}
			secondaryButtonProps={{
				onClick: () => history.push(isEquipment ? APP_ROUTES.VISIT : APP_ROUTES.HOME),
				label: intl.formatMessage(secondaryButtonMessage)
			}}
		>
			<Stack>
				<Card>
					<Stack spacing={2} width="100%">
						<Box alignSelf="center">
							<img src={isLinked ? HouseSearching : FileSearching} alt="file searching" />
						</Box>
						<Box display="flex" alignItems="center" alignSelf="center">
							<Text variant="h5">{intl.formatMessage(titleMesssage)}</Text>
							<Box marginLeft="8px">
								<CheckIcon color="primary" />
							</Box>
						</Box>
						<Stack>
							{data.filter(element => element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_type' })
								&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_brand' })
								&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_model' })
							).map((info, index) => {
								const isDateOnly = isEquipment &&
									(info.key === intl.formatMessage({ id: `visit.equipmentInfo.${EquipmentFieldNames.COMMISSIONING_DATE}` }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.visitDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestWorksInterventionDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestCurativeInterventionDate' })
									)
								return (
									<InfoCell
										dateOnly={isDateOnly}
										label={info.key}
										value={info.key === intl.formatMessage({ id: 'visit.equipmentInfo.documents' }) ? info.value.length.toString() : info.value}
										key={index}
									/>
								)
							})}
						</Stack>
					</Stack>
				</Card>
				{!isEquipment && houseInfo && houseInfo.equipments && houseInfo.equipments?.length > 0 &&
					<Card contentPadding="0 20px" title={intl.formatMessage(linkedEquipmentsMessage)}>
						{houseInfo.equipments.map((equipment) => (
							<Stack width="100%" borderRadius="8px" border={`1px solid ${themeValues.Muted.Border}`} mb="12px">
								<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
									<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(typeMessage)}</Text>
									<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{equipment.type}</Text>
								</Stack>
								<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
									<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(brandMessage)}</Text>
									<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{equipment.brand}</Text>
								</Stack>
								{equipment.visitDate &&
									<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
										<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(visitDateMessage)}</Text>
										<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.visitDate)}</Text>
									</Stack>
								}
								{equipment.latestCurativeInterventionDate &&
									<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
										<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(latestCurativeInterventionDateMessage)}</Text>
										<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.latestCurativeInterventionDate)}</Text>
									</Stack>
								}
								{equipment.latestWorksInterventionDate &&
									<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
										<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(latestWorksInterventionDateMessage)}</Text>
										<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.latestWorksInterventionDate)}</Text>
									</Stack>
								}
							</Stack>
						))}
					</Card>}
			</Stack>
		</PageContainer>
	)
}

export default HouseFoundPage

import React, { useContext, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import Card from '../../../components/layout/Card'
import { CodeContext } from '../../scan/services/context/CodeContext'
import { Box, Stack } from '@mui/material'
import InfoCell from '../../../components/layout/InfoCell'
import Button from '../../../components/Button'
import { ThemeContext } from '../../../config/context/ThemeContext'
import Scanner from '../../scan/components/Scanner'
import { EquipmentFieldNames } from '../../addingSpace/services/addingSpaceConstants'

const EquipmentPage = () => {
	const intl = useIntl()
	const history = useHistory()
	const { equipmentInfoOptions } = useContext(CodeContext)
	const { themeValues } = useContext(ThemeContext)

	const [openScan, setOpenScan] = useState<boolean>(false)
	const [canUpdateQrCode, setCanUpdateQrCode] = useState<boolean>(true)

	const isEquipmentDocumentsEmpty = useMemo(() => (equipmentInfoOptions.filter(element => element.key === intl.formatMessage({ id: 'visit.equipmentInfo.documents' }))[0].value as MustDocument[]).length === 0, [equipmentInfoOptions, intl])

	// i18n
	const addInterventionMessage = { id: 'global.buttons.addIntervention' }
	const returnToVisitMessage = { id: 'global.buttons.returnToVisit' }
	const equipmentInfoMessage = { id: 'intervention.subtitle.equipment' }
	const deleteEquipmentMessage = { id: 'global.buttons.deleteEquipment' }
	const replaceEquipmentQrCodeMessage = { id: 'global.buttons.replaceEquipmentQrCode' }

	// Colors
	const mutedBorderColor = themeValues.Muted.Border
	const mutedBackgroundColor = themeValues.Muted.BGSecondary
	const textColor = themeValues.Muted.SingleTone
	const secondaryBorderColor = themeValues.Secondary.Border
	const secondaryBackgroundColor = themeValues.Secondary.Background
	const secondaryColor = themeValues.Secondary.Base

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => history.push(APP_ROUTES.INTERVENTION, { isAdd: true }),
				label: intl.formatMessage(addInterventionMessage)
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.VISIT),
				label: intl.formatMessage(returnToVisitMessage)
			}}
		>
			<Card title={intl.formatMessage(equipmentInfoMessage)}>
				<Stack spacing={2} width="100%">
					{equipmentInfoOptions &&
						<Box>
							{equipmentInfoOptions.filter(element =>  element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.documents' })
									&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_type' })
									&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_brand' })
									&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_model' })
							).map((info, index) => {
								const isDateOnly =
									(info.key === intl.formatMessage({ id: `visit.equipmentInfo.${EquipmentFieldNames.COMMISSIONING_DATE}` }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.visitDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestWorksInterventionDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestCurativeInterventionDate' })
									)
								return (
									<InfoCell
										displayDivider={index !== 0}
										dateOnly={isDateOnly}
										label={info.key}
										value={info.value}
										key={index}
									/>
								)
							})}
							{!isEquipmentDocumentsEmpty && (equipmentInfoOptions.filter(element => element.key === intl.formatMessage({ id: 'visit.equipmentInfo.documents' }))[0].value as MustDocument[]).map((document, index) => {
								const docUrl = document.url
								return (
									<InfoCell
										label={document.name}
										onClick={() => {
											const newWindow = window.open(docUrl, '_blank', 'noopener,noreferrer')
											if (newWindow) newWindow.opener = null
										}}
										key={index}
									/>
								)
							})}
						</Box>
					}
					<Stack spacing={2} width="100%">
						<Button
							onClick={() => setOpenScan(true)}
							label={intl.formatMessage(replaceEquipmentQrCodeMessage)}
							variant="contained"
							sx={{
								borderColor: mutedBorderColor,
								backgroundColor: mutedBackgroundColor,
								color: textColor
							}}
							disable={!canUpdateQrCode}
						/>
						<Button
							label={intl.formatMessage(deleteEquipmentMessage)}
							onClick={() => history.push(APP_ROUTES.INTERVENTION, { isAdd: true, isDeleting: true })}
							variant="contained"
							color="secondary"
							sx={{
								borderColor: secondaryBorderColor,
								backgroundColor: secondaryBackgroundColor,
								color: secondaryColor
							}}
						/>
					</Stack>
				</Stack>
			</Card>
			<Scanner
				openModal={openScan}
				setOpenModal={setOpenScan}
				setCanReplaceQrCode={setCanUpdateQrCode}
				equipmentOnly
				isUpdatingCode
			/>
		</PageContainer>
	)
}

export default EquipmentPage

import * as actions from './configurationAction'

export const configuration = (state = null , action = {}) => {
	switch (action.type) {
		case actions.GET_CONFIGURATION_SUCCESS:
			return action.configuration
		default:
			return state
	}
}

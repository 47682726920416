import React, { useContext, useEffect, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import { Stack } from '@mui/material'
import Card from '../../../components/layout/Card'
import { useIntl } from 'react-intl'
import Button from '../../../components/Button'
import { ThemeContext } from '../../../config/context/ThemeContext'
import { Muted } from '../../../config/theme'
import InfoCell from '../../../components/layout/InfoCell'
import { CodeContext } from '../../scan/services/context/CodeContext'
import Text from '../../../components/Text'
import { useHistory } from 'react-router-dom'
import { APP_ROUTES } from '../../../containers/routesConstants'
import Scanner from '../../scan/components/Scanner'
import { UserContext } from '../../login/services/context/UserContext'
import { ProfileType } from '../../login/services/userConstants'
import { VisitContext } from '../services/VisitContext'
import Popin from '../../../components/Popin'
import InterventionDisplay from '../components/InterventionDisplay'
import { getHouse } from '../services/visitApi'

const VisitPage = () => {
	const intl = useIntl()
	const history = useHistory()
	const { useColorModeValue, themeValues } = useContext(ThemeContext)
	const { houseInfoOptions, setHouseInfo } = useContext(CodeContext)
	const { mustUser } = useContext(UserContext)
	const { deleteVisit, visit } = useContext(VisitContext)

	const [openScanner, setOpenScanner] = useState<boolean>(false)
	const [canReplaceQrCode, setCanReplaceQrCode] = useState<boolean>(true)
	const [equipmentOnly, setEquipmentOnly] = useState<boolean>(false)
	const [openDeletePopin, setOpenDeletePopin] = useState<boolean>(false)

	const isUserTechnician = useMemo(() => mustUser?.type === ProfileType.TECHNICIAN, [mustUser])

	// i18n
	const infoMessage = { id: 'visit.subtitle.info' }
	const interventionMessage = { id: 'visit.subtitle.intervention' }
	const linkedEquipmentMessage = { id: 'visit.subtitle.linkedEquipment' }
	const endVisitMessage = { id: 'global.buttons.endVisit' }
	const cancelVisitMessage = { id: 'global.buttons.cancelVisit' }
	const replaceQrCodeMessage = { id: 'global.buttons.replaceQrCode' }
	const scanEquipmentMessage = { id: 'global.buttons.scanEquipment' }
	const deleteVisitMessage = { id: 'visit.cancelVisit.content' }
	const deleteVisitTitle = { id: 'visit.cancelVisit.title' }
	const validateMessage = { id: 'global.buttons.validate' }
	const equipmentMessage = isUserTechnician ? interventionMessage : linkedEquipmentMessage

	// Colors
	const buttonTextColor = themeValues.Muted.SingleTone
	const bgColor = useColorModeValue(Muted.Light.BGPrimary, Muted.Dark.BGSecondary)
	const buttonBgColor = useColorModeValue(Muted.Light.BGSecondary, Muted.Dark.BGPrimary)
	const primaryButtonBgColor = themeValues.Primary.Background
	const primaryButtonTextColor = themeValues.Primary.Base
	const primaryButtonBorderColor = themeValues.Primary.Border

	useEffect(() => {
		if (!houseInfoOptions.length && visit) {
			getHouse(visit.spaceId).then(setHouseInfo)
		}
	}, [houseInfoOptions.length, visit, setHouseInfo])

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => history.push(APP_ROUTES.VISIT_END),
				label: intl.formatMessage(endVisitMessage)
			}}
			secondaryButtonProps={{
				onClick: () => setOpenDeletePopin(true),
				label: intl.formatMessage(cancelVisitMessage)
			}}
		>
			<Stack>
				<Card title={intl.formatMessage(infoMessage)}>
					<Stack spacing={2} width="100%">
						<Stack>
							{houseInfoOptions.map((info, index) => (
								<InfoCell key={`${info.key}-${info.value}`} displayDivider={index !== 0} label={info.key} value={info.value} />
							))}
						</Stack>
						{isUserTechnician &&
							<Button
								variant="outlined"
								disable={!canReplaceQrCode}
								onClick={() => setOpenScanner(true)}
								label={intl.formatMessage(replaceQrCodeMessage)}
								sx={{
									bgcolor: buttonBgColor,
									borderColor: bgColor,
									color: buttonTextColor
								}}
							/>}
					</Stack>
				</Card>
				{isUserTechnician &&
					<Card title={intl.formatMessage(equipmentMessage).toUpperCase()}>
						<Stack spacing={2} width="100%">
							<Button
								onClick={() => {
									setEquipmentOnly(true)
									setOpenScanner(true)
								}}
								variant="outlined"
								label={intl.formatMessage(scanEquipmentMessage)}
								sx={{
									bgcolor: primaryButtonBgColor,
									borderColor: primaryButtonBorderColor,
									color: primaryButtonTextColor
								}}
							/>
							<InterventionDisplay />
						</Stack>
					</Card>
				}
			</Stack>
			<Scanner
				isUpdatingCode={!equipmentOnly}
				openModal={equipmentOnly ? equipmentOnly : openScanner}
				setOpenModal={equipmentOnly ? setEquipmentOnly : setOpenScanner}
				setCanReplaceQrCode={!equipmentOnly ? setCanReplaceQrCode : undefined}
				equipmentOnly={equipmentOnly}
			/>
			<Popin
				label={intl.formatMessage(deleteVisitTitle)}
				modalOpen={openDeletePopin}
				setModalOpen={setOpenDeletePopin}
			>
				<Stack spacing={2}>
					<Text variant="subtitle1">{intl.formatMessage(deleteVisitMessage)}</Text>
					<Button
						color="primary"
						variant="contained"
						label={intl.formatMessage(validateMessage)}
						onClick={() => {
							setOpenDeletePopin(false)
							deleteVisit()
							history.push(APP_ROUTES.HOME)
						}}
					/>
				</Stack>
			</Popin>
		</PageContainer>
	)
}

export default VisitPage

import { createContext } from 'react'

type ToastContextProps = {
	addToast: (type: 'success' | 'error' | 'warning' | 'info', message: string) => void
}

const context: ToastContextProps = {
	addToast: () => {
	}
}

export const ToastContext = createContext(context)
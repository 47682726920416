import React, { useContext } from 'react'
import { VisitContext } from '../services/VisitContext'
import Text from '../../../components/Text'
import { ThemeContext } from '../../../config/context/ThemeContext'
import { useIntl } from 'react-intl'
import InterventionCell from './InterventionCell'
import { Stack } from '@mui/material'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'

const InterventionDisplay: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()

	const { visit } = useContext(VisitContext)
	const { themeValues } = useContext(ThemeContext)

	// i18n
	const noInterventionMessage = { id: 'visit.noIntervention' }

	// Colors
	const subTextColor = themeValues.Muted.Content3

	if (!visit || (visit && !visit.interventions.length)) {
		return (
			<Text variant="subtitle1" sx={{ color: subTextColor }}>{intl.formatMessage(noInterventionMessage)}</Text>
		)
	}

	return (
		<Stack spacing={2}>
			{visit && visit.interventions.map((intervention, index) => (
				<InterventionCell
					onClick={() => history.push(APP_ROUTES.INTERVENTION, { isAdd: false, interventionIndex: index })}
					data={intervention} key={index}
				/>
			))}
		</Stack>
	)
}

export default InterventionDisplay
import React, { useMemo, useState } from 'react'
import { CodeContext } from './CodeContext'
import { useIntl } from 'react-intl'

const CodeProvider: React.FC = ({ children }) => {
	const intl = useIntl()

	// States
	const [houseInfo, setHouseInfo] = useState<HouseInfo>()
	const [spaceInfo, setSpaceInfo] = useState<Record<string, any>>()
	const [code, setCode] = useState<string>()
	const [hasQrCode, setHasQrCode] = useState<boolean>(false)
	const [equipmentInfo, setEquipmentInfo] = useState<EquipmentInfo>()
	const [equipmentDataInfo, setEquipmentDataInfo] = useState<Record<string, any>>()

	// Memoized values
	const houseInfoOptions = useMemo(() => houseInfo ?
		Object.keys(houseInfo)
			.filter(key => (key !== 'id' && key !== 'equipments' && houseInfo[key as keyof HouseInfo] !== null && houseInfo[key as keyof HouseInfo] !== undefined))
			.map(info => {
				const infoMessage = { id: `visit.houseInfo.${info}` }
				return ({
					key: intl.formatMessage(infoMessage),
					// @ts-ignore
					value: houseInfo[info]
				})
			}) : [], [houseInfo, intl])
	const equipmentInfoOptions = useMemo(() => equipmentInfo ?
		Object.keys(equipmentInfo)
			.filter(key => (key !== 'id' && key !== 'equipments' && equipmentInfo[key as keyof EquipmentInfo] !== null && equipmentInfo[key as keyof EquipmentInfo] !== undefined))
			.map(info => {
				const infoMessage = { id: `visit.equipmentInfo.${info}` }
				return ({
					key: intl.formatMessage(infoMessage),
					// @ts-ignore
					value: equipmentInfo[info]
				})
			}) : [], [equipmentInfo, intl])

	const contextValue = useMemo(() => ({
		houseInfo,
		houseInfoOptions,
		setHouseInfo,
		spaceInfo,
		setSpaceInfo,
		code,
		setCode,
		hasQrCode,
		setHasQrCode,
		equipmentInfo,
		equipmentInfoOptions,
		setEquipmentInfo,
		equipmentDataInfo,
		setEquipmentDataInfo
	}), [
		houseInfo,
		houseInfoOptions,
		setHouseInfo,
		spaceInfo,
		setSpaceInfo,
		code,
		setCode,
		hasQrCode,
		setHasQrCode,
		equipmentInfo,
		equipmentInfoOptions,
		setEquipmentInfo,
		equipmentDataInfo,
		setEquipmentDataInfo
	])

	return (
		<CodeContext.Provider value={contextValue}>
			{children}
		</CodeContext.Provider>
	)
}

export default CodeProvider

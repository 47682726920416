import React, { useContext, useEffect, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Loading from '../../../components/layout/Loading'
import Scanner from '../../scan/components/Scanner'
import Card from '../../../components/layout/Card'
import { Box, Stack } from '@mui/material'
import MemoryStorage from '../../../assets/images/memory_storage.svg'
import Text from '../../../components/Text'
import { capitalizeWord } from '../../../utils/stringUtils'
import { displayProfileType, ProfileType } from '../../login/services/userConstants'
import { UserContext } from '../../login/services/context/UserContext'
import { useIntl } from 'react-intl'
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { VisitContext } from '../../visit/services/VisitContext'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { ThemeContext } from '../../../config/context/ThemeContext'
import Popin from '../../../components/Popin'
import Button from '../../../components/Button'
import { CodeContext } from '../../scan/services/context/CodeContext'
import { postScanInfo } from '../../scan/services/scanApi'
import { ErrorConstants } from '../../../components/errorConstants'
import { ToastType } from '../../toast/toastConstants'
import { ToastContext } from '../../toast/ToastContext'

//i18n
const profileTitleMessage = { id: 'profile.title' }
const welcomeMessage = { id: 'profile.welcome' }
const scanSpaceMessage = { id: 'global.buttons.scanSpace' }
const scanCodeMessage = { id: 'global.buttons.scanCode' }
const takePhotoMessage = { id: 'global.buttons.takePhoto' }
const validateMessage = { id: 'global.buttons.validate' }
const pendingVisitTitleMessage = { id: 'visit.pending.title' }
const pendingVisitMessage = { id: 'visit.pending.content' }
const continueVisitMessage = { id: 'global.buttons.continueVisit' }
const equipmentConfirmTitleMessage = { id: 'scan.equipment.title' }
const equipmentConfirmMessage = { id: 'scan.equipment.content' }
const confirmMessage = { id: 'global.buttons.confirm' }

const HomePage = () => {
	const intl = useIntl()
	const history = useHistory()
	const { mustUser } = useContext(UserContext)
	const { addToast } = useContext(ToastContext)
	const { visit, checkVisit, deleteVisit, initVisit } = useContext(VisitContext)
	const { themeValues } = useContext(ThemeContext)

	const [isSearching, setIsSearching] = useState<boolean>(false)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [openNewVisit, setOpenNewVisit] = useState<boolean>(false)

	const [openEquipmentPopin, setOpenEquipmentPopin] = useState<boolean>(false)
	const { setHouseInfo, setCode, setEquipmentInfo, houseInfo } = useContext(CodeContext)

	const scanMessage = mustUser?.type === 'technician' ? scanSpaceMessage : scanCodeMessage

	// Colors
	const textColor = themeValues.Muted.Content2

	const profileName = useMemo(() => mustUser ? ` ${capitalizeWord(mustUser.firstname)} ${capitalizeWord(mustUser.lastname)}` : '', [mustUser])
	const profileType = useMemo(() => `${intl.formatMessage(profileTitleMessage).toUpperCase()} ${displayProfileType(mustUser?.type as ProfileType)}`, [mustUser, intl])
	const isUserTechnician = useMemo(() => mustUser?.type === 'technician', [mustUser])

	const isVisiting = useMemo(() => isUserTechnician && (visit?.isVisiting || false), [visit, isUserTechnician])

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const qrCode = urlParams.get('id')
		if (qrCode && qrCode.length > 0) {
			setIsSearching && setIsSearching(true)
			setCode(qrCode)
			if (mustUser) {
				postScanInfo({ userType: mustUser.type, qrCode: qrCode, spaceId: mustUser.id.toString(), equipmentOnly: false })
					.then((res) => {
						const { equipment, houseInfo } = res
						if (isUserTechnician) {
							if (houseInfo && !equipment) {
								setHouseInfo(res.houseInfo || undefined)
								history.push(isUserTechnician ? APP_ROUTES.FOUND : APP_ROUTES.VISIT, { isLinked: false })
							} else if (houseInfo && equipment) {
								setEquipmentInfo(res.equipment || undefined)
								setHouseInfo(res.houseInfo || undefined)
								setOpenEquipmentPopin(true)
							} else if (!houseInfo && !equipment) {
								history.push(APP_ROUTES.ADD_HOUSE)
							}
						} else {
							if (houseInfo && !equipment) {
								setHouseInfo(res.houseInfo || undefined)
								history.push(APP_ROUTES.CLIENT_FOUND)
							} else if (houseInfo && equipment) {
								setEquipmentInfo(res.equipment || undefined)
								setHouseInfo(res.houseInfo || undefined)
								history.push(APP_ROUTES.CLIENT_FOUND)
							}
						}
					})
					.catch((error) => {
						if (Object.keys(ErrorConstants).includes(error.message)) {
							addToast(ToastType.ERROR, `global.errors.${error.message}`)
						} else {
							addToast(ToastType.ERROR, 'global.errors.occurred')
						}
					})
					.finally(() => {
						setIsSearching && setIsSearching(false)
					})
			}
		}
		checkVisit()
	}, [mustUser, isUserTechnician, addToast, checkVisit, history, setCode, setEquipmentInfo, setHouseInfo])

	const mainPageContent = useMemo(() => isSearching ? <Loading setIsSearching={setIsSearching} /> : (
		<PageContainer
			mainButtonProps={{
				onClick: () => (isUserTechnician && isVisiting) ? setOpenNewVisit(true) : setOpenModal(true),
				label: (
					<Stack alignItems="flex-start" direction="column">
						<Text color="inherit" variant="h5">{intl.formatMessage(scanMessage)}</Text>
						<Text color="inherit" variant="subtitle2">{intl.formatMessage(takePhotoMessage).toUpperCase()}</Text>
					</Stack>
				),
				height: '100px',
				startIcon: <WallpaperOutlinedIcon fontSize="large" />,
				endIcon: <ChevronRightOutlinedIcon fontSize="large" />
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.VISIT),
				label: intl.formatMessage(continueVisitMessage),
				notDisplay: !(isUserTechnician && isVisiting)
			}}
		>
			<Card height={!(isUserTechnician && isVisiting) ? window.innerHeight - 280 : window.innerHeight - 324} center>
				<Box margin="20px">
					<img src={MemoryStorage} alt="memory storage" />
				</Box>
				<Box marginBottom="8px">
					<Text display="inline" variant="h5">
						{intl.formatMessage(welcomeMessage)}
						<span style={{ color: themeValues.Primary.Base }}>
							{profileName}
						</span>
					</Text>
				</Box>
				<Text variant="subtitle2">{profileType}</Text>
				{(mustUser && mustUser.logoPath && mustUser.landlordId) && <Box><img alt="client-logo" src={mustUser.logoPath} height={150} width={150}/></Box>}
			</Card>
		</PageContainer>
	), [isSearching, profileName, profileType, isUserTechnician, isVisiting, history, intl, scanMessage, themeValues.Primary.Base, mustUser])

	return (
		<>
			{mainPageContent}
			<Scanner openModal={openModal} setOpenModal={setOpenModal} setIsSearching={setIsSearching} setOpenEquipmentPopin={setOpenEquipmentPopin} />
			<Popin modalOpen={openNewVisit} setModalOpen={setOpenNewVisit} label={intl.formatMessage(pendingVisitTitleMessage)}>
				<Stack spacing={2}>
					<Box>
						<Text sx={{ color: textColor }} variant="subtitle1">{intl.formatMessage(pendingVisitMessage)}</Text>
					</Box>
					<Button
						onClick={() => {
							deleteVisit()
							setOpenNewVisit(false)
							setOpenModal(true)
						}}
						color="primary"
						variant="contained"
						label={intl.formatMessage(validateMessage)}
					/>
				</Stack>
			</Popin>

			<Popin modalOpen={openEquipmentPopin} setModalOpen={setOpenEquipmentPopin} label={intl.formatMessage(equipmentConfirmTitleMessage)}>
				<Stack spacing={2}>
					<Box>
						<Text sx={{ color: textColor }} variant="subtitle1">{intl.formatMessage(equipmentConfirmMessage)}</Text>
					</Box>
					<Button
						onClick={() => {
							houseInfo && initVisit(houseInfo.id || '')
							history.push(APP_ROUTES.FOUND, { isEquipment: true })
						}}
						color="primary"
						variant="contained"
						label={intl.formatMessage(confirmMessage)}
					/>
				</Stack>
			</Popin>
		</>
	)
}

export default HomePage

export enum InterventionTypes {
	PREVENT = 'preventive',
	CURATIVE = 'curative',
	WORK = 'works'
}

export enum InterventionFieldNames {
	TYPE = 'type',
	COMMENT = 'comment',
}

export enum EquipmentNames {
	BRAND = 'brand',
	MODEL = 'model',
	SERIAL_NUMBER = 'serialNumber',
	TYPE = 'type',
	COMMISSIONING_DATE = 'commissioningDate'
}
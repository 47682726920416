import React, { Dispatch, SetStateAction } from 'react'
import Card from './Card'
import { Box } from '@mui/material'
import Text from '../Text'
import animationData from '../../assets/lotties/file_searching.json'
import { useIntl } from 'react-intl'
import { Player } from '@lottiefiles/react-lottie-player'
import PageContainer from './PageContainer'

type LoadingProps = {
	setIsSearching: Dispatch<SetStateAction<boolean>>
}

const Loading: React.FC<LoadingProps> = ({ setIsSearching }) => {
	const intl = useIntl()


	//i18n
	const searchingMessage = { id: 'loading.searching' }
	const waitingMessage = { id: 'loading.waiting' }
	const cancelMessage = { id: 'global.buttons.cancel' }

	return (
		<PageContainer
			secondaryButtonProps={{
				onClick: () => setIsSearching(false),
				label: intl.formatMessage(cancelMessage)
			}}
		>
			<Card height={window.innerHeight - 300} center>
				<Box marginBottom="28px">
					<Player autoplay loop src={animationData} />
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Text variant="h5">{intl.formatMessage(searchingMessage)}</Text>
					<Text variant="h5" color="primary">{intl.formatMessage(waitingMessage)}</Text>
				</Box>
			</Card>
		</PageContainer>
	)
}

export default Loading
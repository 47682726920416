import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import Text from '../../../Text'
import ErrorMessage from '../../../ErrorMessage'

const FormInput = (props) => {

	const {
		label,
		name,
		type,
		error,
		meta,
		input
	} = props

	return (
		<>
			<Text variant="subtitle1">{label}</Text>
			<TextField
				id={name}
				color="primary"
				variant="outlined"
				size="small"
				type={type}
				fullWidth
				value={input.value}
				onChange={input.onChange}
			/>
			<ErrorMessage disable={!(error && meta.touched)} error={error} />
		</>
	)
}

FormInput.propTypes = {
	/**
	 * Provient de l'injection de props du FormInputHOC, permet le changement de style
	 */
	dirty: PropTypes.bool,
	/**
	 * Si erreur, error = \true\ sinon imposé à false
	 */
	error: PropTypes.node
}

export default FormInput

import { createContext } from 'react'

type ThemeContextProps = {
	theme: 'light' | 'dark'
	toggleTheme: () => void
	useColorModeValue: (light: string, dark: string) => string
	themeValues: ThemeValues
}

const context: ThemeContextProps = {
	theme: 'light',
	toggleTheme: () => {
	},
	useColorModeValue: () => '',
	themeValues: {} as ThemeValues
}

export const ThemeContext = createContext(context)
import React, { useCallback, useMemo, useState } from 'react'
import { VisitContext } from './VisitContext'

const VisitProvider: React.FC = ({ children }) => {
	const [visit, setVisit] = useState<Visit>()

	const initVisit = useCallback((spaceId: string) => {
		const newVisit = {
			isVisiting: true,
			entryDate: new Date().toISOString(),
			exitDate: '',
			interventions: [],
			spaceId: spaceId
		}
		setVisit(newVisit)
		localStorage.setItem('visit', JSON.stringify(newVisit))
	}, [])

	const deleteVisit = useCallback(() => {
		localStorage.removeItem('visit')
		setVisit(undefined)
	}, [])

	const checkVisit = useCallback(() => {
		const currentVisit = localStorage.getItem('visit')
		if (currentVisit) {
			setVisit(JSON.parse(currentVisit))
		}
	}, [])

	const addIntervention = useCallback((intervention: InterventionData) => {
		const currentVisit = localStorage.getItem('visit')
		if (currentVisit) {
			const jsonVisit = JSON.parse(currentVisit)
			jsonVisit.interventions.push(intervention)
			setVisit(jsonVisit)
			localStorage.setItem('visit', JSON.stringify(jsonVisit))
		}
	}, [])

	const deleteIntervention = useCallback((index: number) => {
		const currentVisit = localStorage.getItem('visit')
		if (currentVisit) {
			const jsonVisit = JSON.parse(currentVisit)
			jsonVisit.interventions.splice(index, 1)
			setVisit(jsonVisit)
			localStorage.setItem('visit', JSON.stringify(jsonVisit))
		}
	}, [])

	const modifyIntervention = useCallback((intervention: InterventionData, index: number) => {
		const currentVisit = localStorage.getItem('visit')
		if (currentVisit) {
			const jsonVisit = JSON.parse(currentVisit)
			jsonVisit.interventions[index] = intervention
			setVisit(jsonVisit)
			localStorage.setItem('visit', JSON.stringify(jsonVisit))
		}
	}, [])

	const contextValue = useMemo(() => ({
		visit,
		initVisit,
		deleteVisit,
		checkVisit,
		addIntervention,
		modifyIntervention,
		deleteIntervention
	}), [visit, initVisit, deleteVisit, checkVisit, addIntervention, modifyIntervention, deleteIntervention])

	return (
		<VisitContext.Provider value={contextValue}>
			{children}
		</VisitContext.Provider>
	)
}

export default VisitProvider
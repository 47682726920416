import React, { useContext } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Button from '../../../components/Button'
import {generationMdp, importBailleur, importEquipement, importUser, importZone} from '../services/importApi'
import { ToastContext } from '../../toast/ToastContext'
import { ToastType } from '../../toast/toastConstants'
import { Stack } from '@mui/material'
import Card from '../../../components/layout/Card'

const ImportPage = () => {
	const { addToast } = useContext(ToastContext)

	const onImportZoneClick = () => {
		importZone()
			.then(() => addToast(ToastType.SUCCESS, 'Zone en cours d\'importation'))
			.catch(() => addToast(ToastType.ERROR, 'Erreur lors de l\'importation'))
	}

	const onImportEquipementClick = () => {
		importEquipement()
			.then(() => addToast(ToastType.SUCCESS, 'Equipement en cours d\'importation'))
			.catch(() => addToast(ToastType.ERROR, 'Erreur lors de l\'importation'))
	}

	const onImportUserClick = () => {
		importUser()
			.then(() => addToast(ToastType.SUCCESS, 'User en cours d\'importation'))
			.catch(() => addToast(ToastType.ERROR, 'Erreur lors de l\'importation'))
	}

	const onImportBailleurClick = () => {
		importBailleur()
			.then(() => addToast(ToastType.SUCCESS, 'Bailleur en cours d\'importation'))
			.catch(() => addToast(ToastType.ERROR, 'Erreur lors de l\'importation'))
	}

	const onGenerationMdpClick = () => {
		generationMdp()
			.then(() => addToast(ToastType.SUCCESS, 'Generation des MDP en cours d\'importation'))
			.catch(() => addToast(ToastType.ERROR, 'Erreur lors de la generation des MDP'))
	}

	return (
		<PageContainer>
			<Card height={window.innerHeight - 300} center>
				<Stack spacing={2}>
					<Button
						onClick={onImportZoneClick}
						label="Importer les zones"
						variant="contained"
						color="primary"
					/>
					<Button
						onClick={onImportEquipementClick}
						label="Importer les equipements"
						variant="contained"
						color="primary"
					/>
					<Button
						onClick={onImportUserClick}
						label="Importer les user"
						variant="contained"
						color="primary"
					/>
					<Button
						onClick={onImportBailleurClick}
						label="Importer les bailleur"
						variant="contained"
						color="primary"
					/>
					<Button
						onClick={onGenerationMdpClick}
						label="Génération des MDP"
						variant="contained"
						color="primary"
					/>
				</Stack>
			</Card>
		</PageContainer>
	)
}

export default ImportPage

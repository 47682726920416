import {push} from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import * as actions from '../services/loginService/loginActions'
import {loadConfiguration} from '../../configuration/configurationInjector'
import GoogleLogin from 'react-google-login'
import { gapi } from 'gapi-script'

const LoginFormGoogle = ({ configuration, logUser, getUser, setLoading, nextPathname }) => {

	const [userDisabled, setUserDisabled] = React.useState(false)

	React.useEffect(() => {
		if(configuration) {
			gapi.load("client:auth2", () => {
				gapi.client.init({
					clientId: configuration.GOOGLE_CLIENT_ID,
					scope:"profile email https://www.googleapis.com/auth/drive",
					plugin_name: "tenderbox"
				})
			})
		}
	}, [configuration])

	const postLogin = ({ code }) => {
		setLoading(true)
		setUserDisabled(false)
		setTimeout(() => {
			const auth2 = window.gapi.auth2.getAuthInstance()
			const user = auth2.currentUser.get()
			const authResponse = user.getAuthResponse()
			logUser({
				googleId: authResponse.id_token,
				accessToken: authResponse.access_token,
				username: user.getBasicProfile().getEmail(),
				code
			})
				.then(getUser)
				.then(() => {
					if (nextPathname) {
						window.location.href = nextPathname
					} else {
						window.location.href = '/'
					}
				})
				.catch((e) => {
					console.log(e)
					if (e && e.disabledUser) {
						setUserDisabled(true)
					}
					setLoading(false)
				})
		}, 1000)
	}

	return (<>
			{userDisabled && <div style={{ color: '#d32f2f', marginBottom: 20 }}>
				Your account is disabled. Please contact your administrator.
			</div>}
			<div className="signGoogle">
				{configuration && <GoogleLogin
					clientId={configuration.GOOGLE_CLIENT_ID}
					buttonText="Accès technicien"
					onSuccess={postLogin}
					onFailure={(e) => console.log('Erreur lors de la connexion google', e)}
					responseType="code"
					accessType="offline"
					scope="profile email"
				/>}
			</div>
		</>
	)
}

LoginFormGoogle.propTypes = {
	logUser: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
	setUser: PropTypes.func.isRequired,
	redirect: PropTypes.func.isRequired,
	configuration: PropTypes.object,
	nextPathname: PropTypes.string
}

const mapStateToProps = (state) => {
	return ({
		user: state.user
	})
}

const dispatchToProps = {
	logUser: actions.logUserGoogle,
	getUser: actions.getUserGoogle,
	setUser: actions.getUser,
	redirect: (nextPathname) => (dispatch) => {
		if (nextPathname) {
			dispatch(push(nextPathname))
		} else {
			dispatch(push('/'))
		}
	}
}

export default compose(
	loadConfiguration,
	connect(mapStateToProps, dispatchToProps)
)(LoginFormGoogle)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { replace } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Box } from '@mui/material'
import LoginForm from './components/LoginForm'
import BaselineLogo from '../../assets/images/baseline_logo.png'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import PageContainer from '../../components/layout/PageContainer'
import LoginFormGoogle from './components/LoginFormGoogle'
import { RingSpinnerOverlay } from 'react-spinner-overlay'

const useStyles = makeStyles(() =>
	createStyles({
		imageContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: '40px'
		}
	})
)


const LoginPage = ({
	location,
	user,
	goToAccueil
}) => {

	const classes = useStyles()

	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		if (user && user.authenticated && user.username) {
			goToAccueil()
		}
	}, [user, goToAccueil])

	return (
		<PageContainer useDefaultBgColor useTopBar={false} center>
			<Box paddingX="30px">
				<RingSpinnerOverlay
					loading={loading}
					overlayColor="rgba(255, 255, 255,0.8)"
					color="green"
					size={70}
				/>
				<Box className={classes.imageContainer}>
					<img src={BaselineLogo} alt="baseline logo" style={{maxWidth : "360px"}}/>
				</Box>

				<LoginForm
					nextPathname={location.state ? location.state.nextPathname : undefined}
					setLoading={setLoading}
				/>

				<LoginFormGoogle
					nextPathname={location.state ? location.state.nextPathname : undefined}
					setLoading={setLoading}
				/>
			</Box>
		</PageContainer>
	)
}

LoginPage.propTypes = {
	/**
	 * Utilisé pour savoir d'où l'utilisateur vient et le rediriger après login
	 */
	location: PropTypes.object,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	user: PropTypes.object,
	goToAccueil: PropTypes.func
}

const mapStateToProps = (state) => {
	return ({
		user: state.user
	})
}

const mapDispatchToProps = (dispatch) => ({
	goToAccueil: () => dispatch(replace('/'))
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(LoginPage)


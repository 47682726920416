import React from 'react'
import FormInput from './internal/FormInput'

const FormInputHoc = (Component) => (
	{
		input: {
			...inputProps
		},
		meta: {
			error,
			submitError,
			dirty,
			...otherMeta
		},
		...otherProps
	}
) => {

	return <Component
		{...otherProps}
		{...inputProps}
		input ={inputProps}
		meta={otherMeta}
		dirty={dirty}
		error={error || submitError}
	/>
}

const EnhancedFormInput = FormInputHoc(FormInput)

export default EnhancedFormInput


import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { InterventionFieldNames } from '../../intervention/services/interventionConstants'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { Form } from 'react-final-form'
import { Stack } from '@mui/material'
import TextInput from '../../../components/TextInput'
import { VISIT_END_FIELDS } from '../services/visitConstants'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { VisitContext } from '../services/VisitContext'
import { postVisit } from '../services/visitApi'
import { ToastContext } from '../../toast/ToastContext'
import { ToastType } from '../../toast/toastConstants'

const VisitEndPage: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()

	const { visit, deleteVisit } = useContext(VisitContext)
	const { addToast } = useContext(ToastContext)

	const [disableButton, setDisableButton] = useState<boolean>(false)
	const [values, setValues] = useState<Record<string, any>>()

	// i18n
	const endVisitMessage = { id: 'visit.buttons.end' }
	const goBackMessage = { id: 'global.buttons.goBack' }
	const endTitleMessage = { id: 'visit.endVisit.title' }
	const commentMessage = { id: 'visit.endVisit.fields.comment' }
	const interventionNumberMessage = { id: 'visit.endVisit.fields.interventionNumber' }

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => {
					const body = { ...visit, ...values, exitDate: new Date().toISOString() }
					postVisit(body as Visit)
						.then(() => {
							deleteVisit()
							history.push(APP_ROUTES.VISIT_CONFIRMATION)
						})
						.catch(() => addToast(ToastType.ERROR, 'global.errors.occurred'))
				},
				disabled: disableButton,
				label: intl.formatMessage(endVisitMessage)
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.VISIT),
				label: intl.formatMessage(goBackMessage)
			}}
		>
			<Card title={intl.formatMessage(endTitleMessage)}>
				<Form
					onSubmit={() => {
					}}
					render={({ handleSubmit, values }) => {
						if (values[VISIT_END_FIELDS.INTERVENTION_NUMBER]) {
							setDisableButton(false)
						} else {
							setDisableButton(true)
						}

						setValues(values)

						return (
							<form style={{ width: '100%' }} onSubmit={handleSubmit}>
								<Stack spacing={2} width="100%">
									<TextInput
										name={VISIT_END_FIELDS.INTERVENTION_NUMBER}
										label={intl.formatMessage(interventionNumberMessage)}
										required
									/>
									<TextInput
										name={InterventionFieldNames.COMMENT}
										label={intl.formatMessage(commentMessage)}
										multiline
									/>
								</Stack>
							</form>
						)
					}}
				/>
			</Card>
		</PageContainer>
	)
}

export default VisitEndPage
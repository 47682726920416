import React, { useContext, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { EquipmentFieldNames } from '../services/addingSpaceConstants'
import { useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { Stack } from '@mui/material'
import TextInput from '../../../components/TextInput'
import { CodeContext } from '../../scan/services/context/CodeContext'
import { postEquipment } from '../services/addingSpaceApi'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { ToastType } from '../../toast/toastConstants'
import { ToastContext } from '../../toast/ToastContext'
import DatePickerInput from '../../../components/DatePickerInput'
import { postScanInfo } from '../../scan/services/scanApi'
import { ErrorConstants } from '../../../components/errorConstants'
import { UserContext } from '../../login/services/context/UserContext'
import AutocompleteSelectInput from '../../../components/AutocompleteSelectInput'

const AddingEquipmentPage = () => {
	const intl = useIntl()
	const history = useHistory()
	const { equipmentDataInfo, houseInfo, code, setEquipmentInfo } = useContext(CodeContext)
	const { addToast } = useContext(ToastContext)
	const { mustUser } = useContext(UserContext)

	const [disableButton, setDisableButton] = useState<boolean>(true)
	const [values, setValues] = useState<Record<string, any>>()

	// i18n
	const linkCodeMessage = { id: 'global.buttons.linkCode' }
	const cancelMessage = { id: 'global.buttons.cancel' }
	const typeMessage = { id: 'linkEquipment.fields.type' }
	const brandMessage = { id: 'linkEquipment.fields.brand' }
	const modelMessage = { id: 'linkEquipment.fields.model' }
	const serialNumberMessage = { id: 'linkEquipment.fields.serialNumber' }
	const commissioningDateMessage = { id: 'linkEquipment.fields.commissioningDate' }
	const mainTitleMessage = { id: 'linkEquipment.header' }
	const titleMessage = { id: 'linkEquipment.title' }

	// Memo
	const typesOptions: SelectOption[] = useMemo(() => equipmentDataInfo ? Object.keys(equipmentDataInfo).map(type => ({
		label: equipmentDataInfo[type].libelle,
		value: equipmentDataInfo[type].id
	})) : [], [equipmentDataInfo])


	const onSubmit = () => {
		if (values && houseInfo && code) {
			postEquipment({
				type: values[EquipmentFieldNames.TYPE],
				brand: values[EquipmentFieldNames.BRAND],
				model: values[EquipmentFieldNames.MODEL],
				serialNumber: values[EquipmentFieldNames.SERIAL_NUMBER],
				commissioningDate: values[EquipmentFieldNames.COMMISSIONING_DATE],
				spaceId: houseInfo.id,
				qrCode: code
			})
				.then(() => history.push(APP_ROUTES.EQUIPMENT))
				.catch(() => addToast(ToastType.ERROR, 'global.errors.occurred'))
		}
	}

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => {
					if (values && houseInfo && code && mustUser) {
						postEquipment({
							type: values[EquipmentFieldNames.TYPE],
							brand: values[EquipmentFieldNames.BRAND],
							model: values[EquipmentFieldNames.MODEL],
							serialNumber: values[EquipmentFieldNames.SERIAL_NUMBER],
							commissioningDate: values[EquipmentFieldNames.COMMISSIONING_DATE] ? values[EquipmentFieldNames.COMMISSIONING_DATE].toLocaleDateString() : '',
							spaceId: houseInfo.id,
							qrCode: code
						})
							.then(() => {
								postScanInfo({ userType: mustUser.type, qrCode: code, spaceId: mustUser.id.toString(), equipmentOnly: true })
									.then((res) => {
										const { equipment } = res
										if (equipment) {
											setEquipmentInfo(res.equipment || undefined)
											history.push(APP_ROUTES.EQUIPMENT)
										}
									}).catch((error) => {
									if (Object.keys(ErrorConstants).includes(error.message)) {
										addToast(ToastType.ERROR, `global.errors.${error.message}`)
									} else {
										addToast(ToastType.ERROR, 'global.errors.occurred')
									}
								})
							})
							.catch(() => addToast(ToastType.ERROR, 'global.errors.occurred'))
					}
				},
				disabled: disableButton,
				label: intl.formatMessage(linkCodeMessage)
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.VISIT),
				label: intl.formatMessage(cancelMessage)
			}}
		>
			<Card title={intl.formatMessage(titleMessage)} mainTitle={intl.formatMessage(mainTitleMessage)}>
				<Form
					onSubmit={onSubmit}
					render={({ dirtyFields, values, handleSubmit }) => {
						//Options
						const brandsOptions: SelectOption[] = equipmentDataInfo && equipmentDataInfo[values[EquipmentFieldNames.TYPE]] ? Object.keys(equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData).map(brand => ({
							label: equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[brand].libelle,
							value: equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[brand].id
						})) : []
						const modelsOptions: SelectOption[] = equipmentDataInfo && equipmentDataInfo[values[EquipmentFieldNames.TYPE]] && equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[values[EquipmentFieldNames.BRAND]] ? Object.keys(equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[values[EquipmentFieldNames.BRAND]].equipementData).map(model => ({
							label: equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[values[EquipmentFieldNames.BRAND]].equipementData[model].libelle,
							value: equipmentDataInfo[values[EquipmentFieldNames.TYPE]].equipementData[values[EquipmentFieldNames.BRAND]].equipementData[model].id
						})) : []

						// Disable button
						if (values[EquipmentFieldNames.TYPE]
							&& values[EquipmentFieldNames.BRAND]
							&& values[EquipmentFieldNames.MODEL]
						) {
							setDisableButton(false)
						} else {
							setDisableButton(true)
						}

						setValues(values)

						return (
							<form style={{ width: '100%' }} onSubmit={handleSubmit}>
								<Stack spacing={2} width="100%">
									<AutocompleteSelectInput
										name={EquipmentFieldNames.TYPE}
										selectOptions={typesOptions}
										label={intl.formatMessage(typeMessage)}
										values={values}
									/>
									<AutocompleteSelectInput
										name={EquipmentFieldNames.BRAND}
										selectOptions={brandsOptions}
										label={intl.formatMessage(brandMessage)}
										disabled={!dirtyFields[EquipmentFieldNames.TYPE]}
										values={values}
									/>
									<AutocompleteSelectInput
										name={EquipmentFieldNames.MODEL}
										selectOptions={modelsOptions}
										label={intl.formatMessage(modelMessage)}
										disabled={!dirtyFields[EquipmentFieldNames.BRAND]}
										values={values}
									/>
									<TextInput
										name={EquipmentFieldNames.SERIAL_NUMBER}
										label={intl.formatMessage(serialNumberMessage)}
										placeholder="-"
									/>
									<DatePickerInput
										label={intl.formatMessage(commissioningDateMessage)}
										name={EquipmentFieldNames.COMMISSIONING_DATE}
									/>
								</Stack>
							</form>
						)
					}
					}
				/>
			</Card>
		</PageContainer>
	)
}

export default AddingEquipmentPage

import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { Box } from '@mui/material'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import Ok from '../../../assets/images/ok.svg'
import Text from '../../../components/Text'
import { ThemeContext } from '../../../config/context/ThemeContext'

const VisitConfirmationPage: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()

	const { themeValues } = useContext(ThemeContext)

	// i18n
	const goHomeMessage = { id: 'global.buttons.returnHome' }
	const interventionMessage = { id: 'visit.endVisit.intervention' }
	const confirmedMessage = { id: 'visit.endVisit.confirmed' }

	return (
		<PageContainer
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.HOME),
				label: intl.formatMessage(goHomeMessage)
			}}
		>
			<Card height={window.innerHeight - 224} center>
				<Box margin="20px">
					<img src={Ok} alt="ok" />
				</Box>
				<Box marginBottom="8px">
					<Text display="inline" variant="h5">
						{intl.formatMessage(interventionMessage) + ' '}
						<span style={{ color: themeValues.Primary.Base }}>
							{intl.formatMessage(confirmedMessage)}
						</span>
					</Text>
				</Box>
			</Card>
		</PageContainer>
	)
}

export default VisitConfirmationPage